import React, { useEffect } from 'react';

export default function AppstreamRedirect(props) {
  useEffect(() => {
    window.location.replace("https://appstream2.ap-southeast-2.aws.amazon.com/authenticate?parameters=eyJ0eXBlIjoiRU5EX1VTRVIiLCJleHBpcmVzIjoiMTcxNTU3NzcwOSIsImF3c0FjY291bnRJZCI6IjI0OTMwMjYxNTkwMiIsInVzZXJJZCI6InJhY2hlbCIsImNhdGFsb2dTb3VyY2UiOiJzdGFjay90ZXN0LXdlYm9zLXN0YWNrIiwiZmxlZXRSZWYiOiJmbGVldC90ZXN0LXdlYm9zLXN0YWNrIiwiYXBwbGljYXRpb25JZCI6IiIsInVzZXJDb250ZXh0IjoiIiwibWF4VXNlckR1cmF0aW9uSW5TZWNzIjoiNTc2MDAifQ%3D%3D&signature=Wfj4cm9uvDeJglH7DspdTjnCZcpWbpr7b6A4n7Z4MSk%3D");
  }, []);

  return (
    <div>
    </div>
  );
}