import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { moveToNextChallengePage, getGameMetadata, getMultipleChoiceAnswers, setLastChallengeResponse } from '../../utils/utils';
import SmallCharacterBubble from '../../components/SmallCharacterBubble';
import PromptContainer from '../../components/PromptContainer';
import ResetGameButton from '../../components/ResetGameButton';

function PromptResultPage(props) {
  const [output, setOutput] = useState('<div class="loading_spinner"><div class="lds-dual-ring"></div></div>');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let ignore = false;

    async function processStoredAnswers() {
      const gameMetadata = getGameMetadata();
      const multipleChoiceAnswers = getMultipleChoiceAnswers();
      let score = 0;
      for (const answer in multipleChoiceAnswers) {
        score += multipleChoiceAnswers[answer].points;
      }

      // dev endpoint
      const url = 'https://api.prod.swag-dispenser.smb.aws.dev/core-gameplay/submit-challenge-response';
      const response = await fetch(url,
        {
          method: 'POST',
          body: JSON.stringify({
            'userID': gameMetadata.userID,
            'gamePlayID': gameMetadata.gamePlay.gamePlayID,
            'challengeID' : gameMetadata.game.gameConfig.gameChallengeIDs[0],
            'userResponse' : multipleChoiceAnswers,
            'score' : score
          })
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (!ignore) {
          console.log('Challenge input response: ', data);
          setOutput(data.challengeResponse);
          setLastChallengeResponse(data);
          setLoading(false);
        }
      } else {
        console.log("Error with game start response", response);
      }
    }

    processStoredAnswers();

    return () => {
      ignore = true;
    };

  }, []);

  


  function DisplayInput() {
    const answers = getMultipleChoiceAnswers();
    return (
      <div>
        Human:
        <div className='prompt_instruction'>
          { answers.instruction.text }
        </div>
        &lt;user-info&gt;
        <div className='prompt_context'>
          { answers.context.text }
        </div>
        &lt;/user-info&gt;
        <div className='prompt_task'>
          { answers.task.text }
        </div>
        Assistant:
      </div>
    );
  }

  return (
    <div id='promt_result_page' className='page_container'>
      <ResetGameButton />
      <SmallCharacterBubble 
        characterImage={props.characterImage}
        characterSpeech={props.characterSpeech}
      />
      <PromptContainer label='Input'>
        <DisplayInput />
      </PromptContainer>
      <PromptContainer label='Output'>
        <span dangerouslySetInnerHTML={{__html: output}}></span>
      </PromptContainer>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        disabled={loading}
        onClick={() => { moveToNextChallengePage() }}
        sx={{
          mt: 3,
          mb: 2,
          fontSize: '1.5rem',
        }}
      >
        Continue
      </Button>
    </div>
  );
}

export default PromptResultPage;